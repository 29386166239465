import React, { useState } from 'react'
import { Box, Grid, IconButton, Link, Switch } from '@mui/material'
import { CustomerDetails } from '../get-customer-details-slice'
import { formatDate, secureFileDownload } from '../../../../utils/functions'
import { SummaryCard } from '../../../../components/summary-card'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CustomerSubscriptionInformationDialog from '../customer-subscription-information/customer-subscription-information'
import { usePermission } from '../../../../hooks/use-permission'
import { AnilityScopes } from '../../../../interfaces/anility-scopes'
import CustomerUsersList from '../customer-users-list'
import CustomerSubsidiariesList from '../customer-subsidiaries-list'
import { AddCustomerSubsidiaryForm } from '../customer-subsidiary-details/add-customer-subsidiary-form'
import { UpdateCustomerSubsidiaryForm } from '../customer-subsidiary-details/update-customer-subsidiary-form'
import { InviteCustomerUserDialog } from './invite-customer-user-dialog'
import dayjs from 'dayjs'
import { useAppSelector } from '../../../../hooks/hooks'

export const CustomerDetailsSummary = ({
  customerDetails,
  refreshCustomerDetails
}: {
  customerDetails?: CustomerDetails,
  refreshCustomerDetails : () => void
}) => {
  const { tokenState } = useAppSelector((state) => state)
  const [showCustomerSubscriptionConfirmationDialog, setShowCustomerSubscriptionConfirmationDialog] = useState(false)
  const [showCustomerUserListDialog, setShowCustomerUserListDialog] = useState(false)
  const [completedAssessmentsToggle, setCompletedAssessmentsToggle] = useState(false)
  const [showCustomerSubsidiariesDialog, setShowCustomerSubsidiariesDialog] = useState(false)
  const [showCustomerSubsidiariesFormDialog, setShowCustomerSubsidiariesFormDialog] = useState(false)
  const [selectedCustomerSubsidiaryId, setSelectedCustomerSubsidiaryId] = useState<number | undefined>(undefined)
  const [showInviteTeamMemberDialog, setShowInviteTeamMemberDialog] = useState(false)
  const [hasWriteSubscription, hasWriteInviteTeamMember, hasReadCustomers] = usePermission([
    AnilityScopes.Write.Subscription, AnilityScopes.Write.InviteTeamMember, AnilityScopes.Read.Customers
  ])

  if (!customerDetails) {
    return null
  }

  const handleCompletedAssessmentsToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompletedAssessmentsToggle(event.target.checked)
  }

  const handleEditSubscriptionPlanClick = () => {
    setShowCustomerSubscriptionConfirmationDialog(true)
  }

  const handleEditSubscriptionPlanClose = () => {
    setShowCustomerSubscriptionConfirmationDialog(false)
  }

  const handleShowCustomerUserConfirmationDialog = () => {
    setShowCustomerUserListDialog(true)
  }

  const handleCustomerUserClose = () => {
    setShowCustomerUserListDialog(false)
  }

  const handleShowCustomerSubsidiariesDialog = () => {
    setShowCustomerSubsidiariesDialog(true)
  }

  const handleCustomerSubsidiariesDialogClose = () => {
    setShowCustomerSubsidiariesDialog(false)
  }

  const handleToggleCustomerSubsidiaryFormDialogVisibility = () => {
    setShowCustomerSubsidiariesFormDialog(show => !show)
    setShowCustomerSubsidiariesDialog(false)
  }

  const handleCustomerSubscriptionDialogClose = () => {
    handleToggleCustomerSubsidiaryFormDialogVisibility()
    setSelectedCustomerSubsidiaryId(undefined)
  }

  const handleSaveCustomerSubsidiarySuccess = () => {
    handleCustomerSubscriptionDialogClose()
    handleShowCustomerSubsidiariesDialog()
  }

  const handleAddCustomerSubsidiaryClick = () => {
    setSelectedCustomerSubsidiaryId(undefined)
    handleToggleCustomerSubsidiaryFormDialogVisibility()
  }

  const handleEditCustomerSubsidiaryClick = (id: number) => {
    setSelectedCustomerSubsidiaryId(id)
    handleToggleCustomerSubsidiaryFormDialogVisibility()
  }

  const handleInviteCustomerUserDialogVisibility = () => {
    setShowCustomerUserListDialog(false)
    setShowInviteTeamMemberDialog((prev) => !prev)
  }

  const handleSuccessInviteCustomerUser = () => {
    setShowInviteTeamMemberDialog((prev) => !prev)
    setShowCustomerUserListDialog(false)
    refreshCustomerDetails()
  }

  const handleExportUsers = () => {
    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${customerDetails?.id}/export-customer-users`, tokenState.token)
  }

  const completedAssessmentsDate = completedAssessmentsToggle ? customerDetails.joinedDate : customerDetails.subscriptionStartDate
  const completedAssessmentsDateFormat = completedAssessmentsToggle ? 'MMMM DD, YYYY' : 'MMMM, YYYY'
  let completedAssessmentsDescription = `Since ${formatDate(completedAssessmentsDate, completedAssessmentsDateFormat)}`

  if (customerDetails.subscriptionPlan === 'PAYG') {
    const lastYear = dayjs(new Date()).add(-1, 'years')
    const joinedDate = dayjs(customerDetails.joinedDate)
    const sinceDate = lastYear.isBefore(joinedDate) ? joinedDate.format(completedAssessmentsDateFormat) : lastYear.format(completedAssessmentsDateFormat)
    completedAssessmentsDescription = completedAssessmentsToggle ? `Since ${sinceDate}` : 'In the last 12 months'
  }

  return (
    <>
      <Box>
        <Grid container rowGap={1} columnGap={1} justifyContent="space-between" marginLeft={0}>
          <SummaryCard
            title="Completed Assessments"
            value={completedAssessmentsToggle ? customerDetails.totalCompletedAssessmentsCount : customerDetails.currentSubscriptionCompletedAssessmentsCount}
            description={completedAssessmentsDescription}
            customButton={<Switch
              title="Completed assessments since joined"
              checked={completedAssessmentsToggle}
              onChange={handleCompletedAssessmentsToggleChange} />}
          />
          <SummaryCard
            title="Subscription Plan"
            value={ customerDetails.subscriptionPlan === 'PAYG' ? 'PAYG' : `${customerDetails.totalActiveAssessmentCount}${customerDetails.totalAssessmentAllocation ? '/' + customerDetails.totalAssessmentAllocation : ''}`}
            description={customerDetails.subscriptionPlan !== 'PAYG' ? `Renews on ${formatDate(customerDetails.nextRenewDate, 'Do MMMM YYYY')}` : undefined}
            customButton={hasWriteSubscription
              ? <IconButton onClick={handleEditSubscriptionPlanClick}>
                <EditOutlinedIcon color='primary' />
              </IconButton>
              : <></>}
            valueVariant="h1" />
          <SummaryCard title="Team Members" value={<Link component='span' onClick={handleShowCustomerUserConfirmationDialog}
            style={{ cursor: 'pointer' }}>{customerDetails.teamMembersCount}</Link>} description="Invited to account" />
          <SummaryCard title="Customer Subsidiaries" value={<Link component='span' onClick={handleShowCustomerSubsidiariesDialog}
            style={{ cursor: 'pointer' }}>{customerDetails.customerSubsidiariesCount}</Link>} description="Allowed subsidiaries" />
        </Grid>
      </Box>
      <CustomerSubscriptionInformationDialog
        customerDetails={customerDetails}
        open={showCustomerSubscriptionConfirmationDialog}
        handleClose={handleEditSubscriptionPlanClose} />
      {showCustomerUserListDialog &&
          <CustomerUsersList
            open={showCustomerUserListDialog}
            id={customerDetails?.id}
            handleClose={handleCustomerUserClose}
            onClickInviteTeamMemberButton={hasWriteInviteTeamMember ? handleInviteCustomerUserDialogVisibility : undefined}
            onExportButtonClick={hasReadCustomers ? handleExportUsers : undefined}/>}
      {showCustomerSubsidiariesDialog &&
          <CustomerSubsidiariesList
            dialogName='Customer Subsidiaries'
            open={showCustomerSubsidiariesDialog}
            id={customerDetails?.id}
            onClose={handleCustomerSubsidiariesDialogClose}
            onAddClick={handleAddCustomerSubsidiaryClick}
            onEditClick={handleEditCustomerSubsidiaryClick}
          />}
      {showCustomerSubsidiariesFormDialog &&
        selectedCustomerSubsidiaryId
        ? <UpdateCustomerSubsidiaryForm
            selectedId={selectedCustomerSubsidiaryId}
            customerId={customerDetails?.id}
            open={showCustomerSubsidiariesFormDialog}
            isSsoEnabled={customerDetails?.isSSOEnabled}
            onSaveSuccess={handleSaveCustomerSubsidiarySuccess}
            onClose={handleCustomerSubscriptionDialogClose}
          />
        : <AddCustomerSubsidiaryForm
          customerId={customerDetails?.id}
          auth0ConnectionId={customerDetails?.auth0ConnectionId}
          isSsoEnabled={customerDetails?.isSSOEnabled}
          open={showCustomerSubsidiariesFormDialog}
          onSaveSuccess={handleSaveCustomerSubsidiarySuccess}
          onClose={handleCustomerSubscriptionDialogClose}
        />}

        {hasWriteInviteTeamMember && showInviteTeamMemberDialog &&
        <InviteCustomerUserDialog
          customerId={customerDetails?.id}
          open={showInviteTeamMemberDialog}
          onSubmitSuccess={handleSuccessInviteCustomerUser}
          onClose={handleInviteCustomerUserDialogVisibility} />
      }
    </>
  )
}
