import './custom.scss'
import { useAuth0 } from '@auth0/auth0-react'
import { Suspense, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { AnilityTheme } from './theme/anility-dark-theme'
import { useAppDispatch, useAppSelector } from './hooks/hooks'
import { setForceLoginWithRedirect, setToken } from './layout/token-slice'
import { ProtectedRoute } from './components/protected-route'
import { AnilityScopes } from './interfaces/anility-scopes'
import { AnilityBackdrop } from './components/anility-backdrop'
import { Report } from './pages/report'
import { Unauthorized } from './pages/unauthorized'
import { NotFound } from './pages/not-found'
import { AnalystTools } from './pages/analyst-tools'
import { CrmTools } from './pages/crm-tools'
import { DeveloperTools } from './pages/developer-tools'
import { AppIndex } from './pages/app-index'
import { VerifyEmail } from './pages/verify-email'
import { FlagsmithProvider } from 'flagsmith/react'
import flagsmith from 'flagsmith'
import { getAxiosWithInterceptor } from './utils/axios-util'
import axios from 'axios'
import { useLogger } from './hooks/use-logger'
import { LoginError } from './pages/login-error'

function App () {
  const { isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { token, forceLoginWithRedirect } = useAppSelector((state) => state.tokenState)
  const { logger } = useLogger('Admin.App')

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently()
        dispatch(setToken(token))
      } catch (e: any) {
        if (e.error === 'login_required') {
          logger?.info('login_required {error}, redirecting to login page', { error: e })
          loginWithRedirect({ appState: { returnTo: location.pathname, search: location.search } })
        }
      }
    }
    if (!isLoading) {
      if (isAuthenticated && !token) {
        getAccessToken()
      }
    }
  }, [isLoading, isAuthenticated, token, getAccessTokenSilently, loginWithRedirect, logger])

  useEffect(() => {
    const axiosInterceptor = getAxiosWithInterceptor(() => dispatch(setForceLoginWithRedirect()))
    return () => {
      axios.interceptors.response.eject(axiosInterceptor.response)
    }
  }, [])

  useEffect(() => {
    if (forceLoginWithRedirect) {
      logger?.info('forceLoginWithRedirect flag is set, redirecting to login page', {})
      loginWithRedirect({ appState: { returnTo: location.pathname, search: location.search } })
    }
  }, [forceLoginWithRedirect, logger])

  return (
    <ThemeProvider theme={AnilityTheme}>
      <FlagsmithProvider options={{ environmentID: process.env.REACT_APP_FLAGSMITH_API_KEY ?? 'FLAGSMITH_API_KEY_UNDEFINED', enableAnalytics: true }}
        flagsmith={flagsmith}>
        <Suspense fallback={<AnilityBackdrop open />}>
          <Routes>
            <Route
              index
              element={<AppIndex />}
            />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/login-error" element={<LoginError />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/report/*"
              element={
                <ProtectedRoute
                  permissions={[
                    AnilityScopes.Read.AdminDashboard,
                    AnilityScopes.Read.Reports,
                    AnilityScopes.Write.Reports
                  ]}>
                  <Report />
                </ProtectedRoute>
              }
            />
            <Route path="/analyst-tools/*"
              element={
                <ProtectedRoute
                  permissions={[
                    AnilityScopes.Read.AdminDashboard,
                    AnilityScopes.Read.Reports
                  ]}>
                  <AnalystTools />
                </ProtectedRoute>
              }
            />
            <Route path="/crm-tools/*"
              element={
                <ProtectedRoute
                  permissions={[
                    AnilityScopes.Read.AdminDashboard,
                    AnilityScopes.Read.Customers,
                    AnilityScopes.Read.EntityAssessed
                  ]} condition="Any">
                  <CrmTools />
                </ProtectedRoute>
              }
            />
            <Route path="/developer-tools/*"
              element={
                <ProtectedRoute
                  permissions={[
                    AnilityScopes.Read.AdminDashboard,
                    AnilityScopes.Write.Events,
                    AnilityScopes.Read.ReportConfiguration
                  ]} condition="Any">
                  <DeveloperTools />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Suspense>
      </FlagsmithProvider>
    </ThemeProvider>
  )
}

export default App
