import React, { useCallback, useEffect, useState } from 'react'
import { Box, Stack, Typography, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, Chip, ListItem, ListItemText, List, Grid } from '@mui/material'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { CustomFile } from '../../../components/upload-file'
import { useNavigate, useParams } from 'react-router-dom'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { ReportWorkspaceDetails, getReportWorkspacesDetails } from './get-report-workspace-details-slice'
import { uploadPPSR, resetLoadingStatus as resetUploadPPSR } from './upload-ppsr-slice'
import { deletePPSR, resetLoadingStatus as resetDeletePPSR } from './delete-ppsr-slice'
import { usePermission } from '../../../hooks/use-permission'
import { markWorkspaceAsDone, resetLoadingStatus as resetMarkWorkspaceAsDone } from './mark-workspace-as-done-slice'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import SeqLogs from './components/seq-logs'
import { resetLoadingStatus as resetRunReportWorkspaceStagesLoadingStatus } from './run-report-workspace-stages-slice'
import { resetLoadingStatus as resetGenerateFinancialViabilityAssessmentLoadingStatus } from './generate-report-workspace-pdf-slice'
import { ReportWorkspaceViewActions } from './components/report-workspace-view-actions'
import { ReportWorkspaceViewNotifications } from './components/report-workspace-view-notifications'
import { resetLoadingStatus as resetSetAnalystComments, setAnalystComments } from './set-analyst-comments-slice'
import '../../../styles/react-md-editor.scss'
import { FVAReportWorkspaceView } from './fva-report-workspace-view'
import { FLAReportWorkspaceView } from './fla-report-workspace-view'
import { getLines, limitNumberOfLines, removeEmptyEntries } from '../../../utils/functions'
import { confirmSpecialRequirements, resetConfirmSpecialRequirementsLoadingStatus } from './confirm-special-requirements-slice'
import { getReportWorkspaceFileAttachments } from './get-report-workspace-file-attachments-slice'
import { FVAReportWorkspaceViewNZ } from './fva-report-workspace-view-nz'
import { ReportWorkspaceViewConstants } from '../../../interfaces/constants'

const GetChips = (reportWorkspaceDetails?: ReportWorkspaceDetails) => {
  const chips: React.ReactElement[] = []
  const hasSpecialRequirements = !!reportWorkspaceDetails?.customerSpecialRequirements || !!reportWorkspaceDetails?.entityAssessedSpecialRequirements
  if (reportWorkspaceDetails?.status === 'Done') {
    chips.push(<Chip key="completed" variant="filled" label="Completed" size="small" color="success" />)
  }
  if (reportWorkspaceDetails?.isDeleted) {
    chips.push(<Chip key="deleted" variant="filled" label="Deleted" size="small" color="error" />)
  }

  if (hasSpecialRequirements) {
    chips.push(<Chip key="deleted" sx={{ color: (theme) => theme.palette.common.white }} variant="filled" label="Special Requirements" size="small" color="error" />)
  }

  return (
    <Stack display="inline-flex" direction="row" columnGap={1} mx={1}>
      {chips}
    </Stack>
  )
}

const ReportWorkspaceView = () => {
  const { id } = useParams()
  const [hasReadReportLogsPermission] = usePermission([
    AnilityScopes.Read.ReportLogs
  ])
  const {
    getReportWorkspaceDetailsState, markWorkspaceAsDoneState,
    runReportWorkspaceStagesState, generateReportWorkspacePdfState,
    setAnalystCommentsState, uploadPPSRState, deletePPSRState,
    confirmSpecialRequirementsState,
    getReportWorkspaceFileAttachmentsState
  } = useAppSelector((state) => state)
  const [expanded, setExpanded] = useState<string | false>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { reportWorkspaceDetails } = getReportWorkspaceDetailsState
  const [showMarkAsCompleteConfirmationDialog, setShowMarkAsCompleteConfirmationDialog] = useState(false)
  const analystCommentsLineLimit = ReportWorkspaceViewConstants.ANALYST_COMMENTS_LIMIT
  const defaultAnalystComments = ReportWorkspaceViewConstants.DEFAULT_ANALYST_COMMENTS
  const [localAnalystComments, setLocalAnalystComments] = useState<string>(reportWorkspaceDetails?.analystComments || defaultAnalystComments)
  const [editAnalystCommentsState, setEditAnalystCommentsState] = useState(false)
  const loading =
    getReportWorkspaceDetailsState.loading === 'loading' ||
    markWorkspaceAsDoneState.loading === 'loading' ||
    runReportWorkspaceStagesState.loading === 'loading' ||
    generateReportWorkspacePdfState.loading === 'loading' ||
    setAnalystCommentsState.loading === 'loading' ||
    uploadPPSRState.loading === 'loading' || deletePPSRState.loading === 'loading' ||
    confirmSpecialRequirementsState.loading === 'loading' || getReportWorkspaceFileAttachmentsState.loading === 'loading'
  const chips = GetChips(reportWorkspaceDetails)
  const showLineLimitWarning = getLines(removeEmptyEntries(localAnalystComments, defaultAnalystComments)).length >= analystCommentsLineLimit
  const assessmentLocked = !reportWorkspaceDetails || reportWorkspaceDetails.status === 'Done' || reportWorkspaceDetails.isDeleted
  const attachmentFiles = getReportWorkspaceFileAttachmentsState?.items.map(_ => _.fileName)
  const hasAttachmentFiles = attachmentFiles.length > 0
  useEffect(() => {
    if (!id) {
      navigate('..')
    } else {
      dispatch(resetRunReportWorkspaceStagesLoadingStatus())
      dispatch(resetGenerateFinancialViabilityAssessmentLoadingStatus())
      dispatch(resetSetAnalystComments())
      dispatch(resetUploadPPSR())
      dispatch(resetDeletePPSR())
      dispatch(
        getReportWorkspacesDetails({
          reportWorkspaceId: id
        })
      )
      dispatch(getReportWorkspaceFileAttachments({
        reportWorkspaceId: id
      }))
      dispatch(resetMarkWorkspaceAsDone())
      dispatch(resetConfirmSpecialRequirementsLoadingStatus())
    }
  }, [id])

  useEffect(() => {
    const tempAnalystComments = reportWorkspaceDetails?.analystComments || defaultAnalystComments
    setLocalAnalystComments(tempAnalystComments)
    setEditAnalystCommentsState(tempAnalystComments === defaultAnalystComments)
  }, [reportWorkspaceDetails])

  const handleMarkAsCompleteConfirmClick = useCallback(() => {
    if (!reportWorkspaceDetails) {
      return
    }

    dispatch(
      markWorkspaceAsDone({
        reportWorkspaceId: reportWorkspaceDetails?.id,
        status: 'Done',
        onSuccess: () => {
          dispatch(
            getReportWorkspacesDetails({
              reportWorkspaceId: reportWorkspaceDetails.id
            })
          )
          navigate('/report/completed-assessments')
        },
        onError: (error) => {
          if (error.statusCode === 400) {
            dispatch(
              getReportWorkspacesDetails({
                reportWorkspaceId: reportWorkspaceDetails.id
              })
            )
          }
          setShowMarkAsCompleteConfirmationDialog(false)
        }
      })
    )
  }, [reportWorkspaceDetails])

  const handleAccordionChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const handleMarkAsDone = () => {
    setShowMarkAsCompleteConfirmationDialog(true)
  }

  const handleSaveAnalystComments = useCallback(() => {
    if (!reportWorkspaceDetails) {
      return
    }
    const tempAnalystComments = removeEmptyEntries(localAnalystComments, defaultAnalystComments)
    dispatch(setAnalystComments({
      reportWorkspaceId: reportWorkspaceDetails.id,
      analystComments: tempAnalystComments,
      onSuccess: () => {
        dispatch(
          getReportWorkspacesDetails({
            reportWorkspaceId: reportWorkspaceDetails.id
          })
        )
      }
    }))
  }, [reportWorkspaceDetails, localAnalystComments])

  const handleDropPpsrFileAccepted = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles?.length > 0 && !!reportWorkspaceDetails?.id) {
      dispatch(uploadPPSR({
        reportWorkspaceId: reportWorkspaceDetails?.id,
        file: acceptedFiles[0],
        onFinal: () => {
          dispatch(resetUploadPPSR())
          dispatch(resetDeletePPSR())
          dispatch(
            getReportWorkspacesDetails({
              reportWorkspaceId: reportWorkspaceDetails.id
            })
          )
        }
      }))
    }
  }, [reportWorkspaceDetails])

  const handleDeletePpsr = useCallback((ppsrFile: CustomFile) => {
    if (ppsrFile.fileName && !!reportWorkspaceDetails?.id) {
      dispatch(deletePPSR({
        reportWorkspaceId: reportWorkspaceDetails.id,
        onFinal: () => {
          dispatch(resetDeletePPSR())
          dispatch(resetUploadPPSR())
          dispatch(
            getReportWorkspacesDetails({
              reportWorkspaceId: reportWorkspaceDetails.id
            })
          )
        }
      }))
    }
  }, [reportWorkspaceDetails])

  const markAsCompleteConfirmationDialog = (
    <CustomDialogForm
      open={showMarkAsCompleteConfirmationDialog}
      primaryText="Yes"
      handleSubmit={handleMarkAsCompleteConfirmClick}
      onClose={() => setShowMarkAsCompleteConfirmationDialog(false)}
      title="Mark as complete?"
      secondaryText="Cancel">
      <Grid px={2} container>
        <Typography>You are setting the status of this assessment to complete.</Typography>
        {hasAttachmentFiles && <Grid item xs={12} container>
        <Typography pt={2} pb={1} component='span'>The following attachment(s) will be sent to the Customer:</Typography>
          <List sx={{
            listStyleType: 'disc',
            '& .MuiListItem-root': {
              display: 'list-item'
            },
            pl: 2
          }}>
            {attachmentFiles.map((attachment) => {
              return <ListItem key={attachment} sx={{ p: 0 }}>
                <ListItemText
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    wordBreak: 'break-word'
                  }}>{attachment}</ListItemText>
              </ListItem>
            })}
          </List>
        </Grid>}
        <Grid item xs={12} textAlign={hasAttachmentFiles ? 'left' : 'center'}>
          <Typography>Are you sure?</Typography>
        </Grid>
      </Grid>
    </CustomDialogForm>
  )

  const getPpsrUploadedFile = () => {
    if (reportWorkspaceDetails?.ppsrReportDataPath &&
      reportWorkspaceDetails?.ppsrReportOriginalFileName) {
      return [{
        fileName: reportWorkspaceDetails?.ppsrReportOriginalFileName,
        fileSize: reportWorkspaceDetails?.ppsrReportFileSize,
        downloadUrl: `${process.env.REACT_APP_API_BASE}/ui/admin/reportworkspace/${reportWorkspaceDetails.id}/download-ppsr`
      }] as CustomFile[]
    }
    return []
  }
  const handleAnalystCommentChange = (val?: string) => {
    const limitedLines = limitNumberOfLines(val || defaultAnalystComments, analystCommentsLineLimit)
    setLocalAnalystComments(limitedLines)
  }

  const handleConfirmSpecialRequirements = useCallback(() => {
    if (!reportWorkspaceDetails) {
      return
    }
    dispatch(confirmSpecialRequirements({
      reportWorkspaceId: reportWorkspaceDetails.id,
      onSuccess: () => {
        dispatch(
          getReportWorkspacesDetails({
            reportWorkspaceId: reportWorkspaceDetails.id
          })
        )
      }
    }))
  }, [reportWorkspaceDetails])

  const reportWorkspaceView: { [key: string]: React.ReactElement } = {
    FVA: <FVAReportWorkspaceView
      showLineLimitWarning={showLineLimitWarning}
      assessmentLocked={assessmentLocked}
      analystCommentsLineLimit={analystCommentsLineLimit}
      isAnalystCommentsEditable={editAnalystCommentsState}
      reportWorkspaceDetails={reportWorkspaceDetails}
      localAnalystComments={localAnalystComments}
      setIsAnalystCommentsEditable={setEditAnalystCommentsState}
      getPpsrUploadedFile={getPpsrUploadedFile}
      handleDeletePpsr={handleDeletePpsr}
      handleDropPpsrFileAccepted={handleDropPpsrFileAccepted}
      handleSaveAnalystComments={handleSaveAnalystComments}
      handleMarkAsDone={handleMarkAsDone}
      handleAnalystCommentChange={handleAnalystCommentChange}
      handleConfirmSpecialRequirements={handleConfirmSpecialRequirements}
    />,
    FVA_V2: <FVAReportWorkspaceView
      showLineLimitWarning={showLineLimitWarning}
      assessmentLocked={assessmentLocked}
      analystCommentsLineLimit={analystCommentsLineLimit}
      isAnalystCommentsEditable={editAnalystCommentsState}
      reportWorkspaceDetails={reportWorkspaceDetails}
      localAnalystComments={localAnalystComments}
      setIsAnalystCommentsEditable={setEditAnalystCommentsState}
      getPpsrUploadedFile={getPpsrUploadedFile}
      handleDeletePpsr={handleDeletePpsr}
      handleDropPpsrFileAccepted={handleDropPpsrFileAccepted}
      handleSaveAnalystComments={handleSaveAnalystComments}
      handleMarkAsDone={handleMarkAsDone}
      handleAnalystCommentChange={handleAnalystCommentChange}
      handleConfirmSpecialRequirements={handleConfirmSpecialRequirements}
    />,
    FVA_NZ_V2: <FVAReportWorkspaceViewNZ
      showLineLimitWarning={showLineLimitWarning}
      assessmentLocked={assessmentLocked}
      analystCommentsLineLimit={analystCommentsLineLimit}
      isAnalystCommentsEditable={editAnalystCommentsState}
      reportWorkspaceDetails={reportWorkspaceDetails}
      localAnalystComments={localAnalystComments}
      setIsAnalystCommentsEditable={setEditAnalystCommentsState}
      handleSaveAnalystComments={handleSaveAnalystComments}
      handleMarkAsDone={handleMarkAsDone}
      handleAnalystCommentChange={handleAnalystCommentChange}
      handleConfirmSpecialRequirements={handleConfirmSpecialRequirements}
    />,
    FVA_NZ_V3: <FVAReportWorkspaceViewNZ
      showLineLimitWarning={showLineLimitWarning}
      assessmentLocked={assessmentLocked}
      analystCommentsLineLimit={analystCommentsLineLimit}
      isAnalystCommentsEditable={editAnalystCommentsState}
      reportWorkspaceDetails={reportWorkspaceDetails}
      localAnalystComments={localAnalystComments}
      setIsAnalystCommentsEditable={setEditAnalystCommentsState}
      handleSaveAnalystComments={handleSaveAnalystComments}
      handleMarkAsDone={handleMarkAsDone}
      handleAnalystCommentChange={handleAnalystCommentChange}
      handleConfirmSpecialRequirements={handleConfirmSpecialRequirements}
    />,
    FLA: <FLAReportWorkspaceView
      showLineLimitWarning={showLineLimitWarning}
      assessmentLocked={assessmentLocked}
      analystCommentsLineLimit={analystCommentsLineLimit}
      isAnalystCommentsEditable={editAnalystCommentsState}
      reportWorkspaceDetails={reportWorkspaceDetails}
      localAnalystComments={localAnalystComments}
      setIsAnalystCommentsEditable={setEditAnalystCommentsState}
      handleSaveAnalystComments={handleSaveAnalystComments}
      handleMarkAsDone={handleMarkAsDone}
      handleAnalystCommentChange={handleAnalystCommentChange} />
  }

  return (
    <>
      <Box p={4} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        rowGap: 0.2
      }}>
        <Card>
          <CardContent>
            <Box pl={2} pt={2} pr={2}>
              <Box display="inline-flex">
                <Typography variant="h3">Create Assessment</Typography>
                {chips}
              </Box>
              <Box pt={2}>
                <Typography variant="label" color={(theme) => theme.palette.text.secondary} sx={{ cursor: 'default' }}>{reportWorkspaceDetails?.assessmentSubject}</Typography>
              </Box>
              <ReportWorkspaceViewActions />
            </Box>
          </CardContent>
        </Card>
        <ReportWorkspaceViewNotifications />
        {reportWorkspaceView[reportWorkspaceDetails?.reportConfigurationId!]}
        {hasReadReportLogsPermission && (
          <Accordion expanded={expanded === 'SEQ'} onChange={handleAccordionChange('SEQ')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{
              pl: 4,
              pr: 3
            }}>SEQ Logs</AccordionSummary>
            <AccordionDetails>
              {reportWorkspaceDetails && (
                <SeqLogs
                  reportWorkspaceDetails={reportWorkspaceDetails}
                />
              )}
            </AccordionDetails>
          </Accordion>)}
      </Box>
      {markAsCompleteConfirmationDialog}
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default ReportWorkspaceView
