import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { BrowserRouter } from 'react-router-dom'
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate'
import { SignalRContextProvider } from './signalr/signalr-context'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <Provider store={store}>
        <SignalRContextProvider url={`${process.env.REACT_APP_API_BASE}/ui/admin/admin-hub`}>
          <App />
        </SignalRContextProvider>
      </Provider>
      </Auth0ProviderWithNavigate>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
