import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useAppSelector } from '../hooks/hooks'

interface SignalRContextType {
    hubConnection: HubConnection | null
    isConnected: boolean
}

const SignalRContext = createContext<SignalRContextType | null>(null)

interface SignalRContextProviderProps {
    children?: ReactNode
    url: string
}

export const SignalRContextProvider = (props: SignalRContextProviderProps) => {
  const { tokenState } = useAppSelector(app => app)
  const [hubConnection, setHubConnection] = useState<HubConnection | null>(null)
  const [isConnected, setIsConnected] = useState<boolean>(false)

  useEffect(() => {
    if (tokenState.token) {
      const createConnection = async () => {
        const newHubConnection = new HubConnectionBuilder()
          .withUrl(props.url, {
            accessTokenFactory: () => tokenState.token
          })
          .withAutomaticReconnect()
          .build()

        try {
          await newHubConnection.start()
          setHubConnection(newHubConnection)
          setIsConnected(true)
        } catch (err) {
          console.error('SignalR connection failed:', err)
        }
      }

      createConnection()

      return () => {
        if (hubConnection) {
          setHubConnection(null)
          setIsConnected(false)
          hubConnection.stop()
        }
      }
    }
  }, [tokenState])

  return <SignalRContext.Provider value={{ hubConnection, isConnected }}>
      { props.children }
    </SignalRContext.Provider>
}

export const useSignalR = () => {
  const context = useContext(SignalRContext)
  if (!context) {
    throw new Error('useSignalR must be used within SignalRContextProvider')
  }
  return context
}
