import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'
import { AssessmentRequestStatus } from '../../../types/assessment-request-status'

export interface GetSubmissionReviewWorkspaceState {
  submissionReviewWorkspace?: SubmissionReviewWorkspace
  loading: LoadingType
  error: string
}

export interface SubmissionReviewWorkspace {
  assessmentRequestId: number
  contractName: string
  financialStatementPreparationCompleted: boolean
  isSubmissionReviewCompleted: boolean
  financialStatementPreparationCompletedDateUtc?: string
  customerName: string
  entityAssessedName: string
  requestLink: string
  responseLink: string
  financialStatementModelLink: string
  checklistLink: string
  crmDealLink: string
  crmStageName?: string
  assignedAnalystUserId?: string
  assessmentStatus: AssessmentRequestStatus
}

const initialState: GetSubmissionReviewWorkspaceState = {
  submissionReviewWorkspace: undefined,
  loading: 'idle',
  error: ''
}

export interface GetSubmissionReviewWorkspaceParams {
  assessmentRequestId: number
}

export const getSubmissionReviewWorkspace = createAsyncThunk<
  SubmissionReviewWorkspace,
  GetSubmissionReviewWorkspaceParams,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('report/getSubmissionReviewWorkspace', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()

  try {
    const response = await axios.get<SubmissionReviewWorkspace>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/${request.assessmentRequestId}/submission-review-workspace`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const getSubmissionReviewWorkspaceSlice = createSlice({
  name: 'get-submission-review-workspace',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSubmissionReviewWorkspace.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getSubmissionReviewWorkspace.fulfilled, (state, { payload }) => {
      state.submissionReviewWorkspace = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getSubmissionReviewWorkspace.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetLoadingStatus } = getSubmissionReviewWorkspaceSlice.actions
