import { Chip } from '@mui/material'
import { CustomerDetails } from '../get-customer-details-slice'

interface CustomerDetailsChipProps {
    customerDetails?: CustomerDetails
}

const labelFactory = (customerDetails: CustomerDetails) => {
  return `${customerDetails.status}${customerDetails.subscriptionPlan ? ' (' + customerDetails.subscriptionPlan + ')' : ''}`
}

const colorFactory = (customerDetails: CustomerDetails) : any => {
  switch (customerDetails.status) {
    case 'Active':
      switch (customerDetails.subscriptionPlan) {
        case 'Subscription':
          return 'subscriptionSubscription'
        case 'Trial':
          return 'subscriptionTrial'
        case 'PAYG':
          return 'subscriptionPayg'
        default:
          return 'success'
      }
    case 'Expired':
      return 'subscriptionExpired'
  }
}

export const CustomerDetailsChip = ({ customerDetails } : CustomerDetailsChipProps) => {
  if (customerDetails) {
    return <Chip variant="filled"
      label={labelFactory(customerDetails)}
      size="small"
      color={colorFactory(customerDetails)}
      sx={{ mx: 1 }} />
  }
  return <></>
}
