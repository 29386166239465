import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { LoadingType } from '../../../../utils/types'
import { AppDispatch, RootState } from '../../../../store/store'
import { getGenericErrorMessage } from '../../../../utils/functions'

interface UpdateCustomerSubscriptionInformation {
  subscriptionPlan: string
  totalAssessmentAllocation?: number
  nextRenewDate?: string
  customerId: number
  onSuccess?: () => void
}

interface UpdateCustomerSubscriptionInformationState {
  loading: LoadingType;
  error: string;
}

const initialState: UpdateCustomerSubscriptionInformationState = {
  loading: 'idle',
  error: ''
}

interface ConflictError {
  message: string;
}
export const updateCustomerSubscriptionInformation = createAsyncThunk<
  void,
  UpdateCustomerSubscriptionInformation,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: ConflictError;
  }
>('customers/updateCustomerSubscriptionInformation', async (request, thunkAPI) => {
  const filteredRequest = { ...request, totalAssessmentAllocation: request.totalAssessmentAllocation ? request.totalAssessmentAllocation : undefined }
  const { tokenState, appSettingsState } = thunkAPI.getState()
  try {
    await axios.patch<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/customers/updateSubscriptionInformation`,
      filteredRequest,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    request.onSuccess?.()
  } catch (error) {
    const errors: AxiosError<{
      title: string, detail: string, status: number,
      error: {
        TotalAssessmentAllocation: string[],
        NextRenewDate: string[],
        SubscriptionPlan: string[]
      }
    }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      const errorData = errors.response.data.error

      if (errorData.SubscriptionPlan?.[0]) {
        return thunkAPI.rejectWithValue({ message: errorData.SubscriptionPlan[0] })
      }

      if (errorData.TotalAssessmentAllocation?.[0]) {
        return thunkAPI.rejectWithValue({ message: errorData.TotalAssessmentAllocation[0] })
      }

      if (errorData.NextRenewDate?.[0]) {
        return thunkAPI.rejectWithValue({ message: errorData.NextRenewDate[0] })
      }
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const updateCustomerSubscriptionInformationSlice = createSlice({
  name: 'update-customer-susbcription',
  initialState,
  reducers: {
    resetUpdateCustomerSubscriptionInformation: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateCustomerSubscriptionInformation.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(updateCustomerSubscriptionInformation.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(updateCustomerSubscriptionInformation.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetUpdateCustomerSubscriptionInformation } = updateCustomerSubscriptionInformationSlice.actions
export default updateCustomerSubscriptionInformationSlice.reducer
